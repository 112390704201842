div.dialog {
  min-width: 280px;
  max-width: 640px;
  width: 80%;
  padding: 32px 20px 16px;
  border-radius: 20px;
  box-shadow: 10px 10px 32px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);

  display: flex;
  flex-direction: column;

  position: relative;
  left: 50%;
  top: 50%;

  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

div.dialog img.logo {
  width: 75px;
  height: 75px;
  margin: 0 auto;
}

div.dialog p.message {
  margin: 16px 0 32px;
  text-align: center;
  color: var(--black);
}

div.dialog button {
  border: 0;
  height: 44px;
  border-radius: 8px;

  cursor: pointer;
}

div.dialog button.btn_down {
  background-color: var(--purple050);
  height: 47px;
  color: var(--white);
}

div.dialog button.btn_cancel {
  background-color: var(--white);
  color: var(--gray030);
}