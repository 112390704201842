.container {
  max-width: 74%;
  margin: 24px auto 24px 16px;
  text-align: left;
}

.box {
  display: inline-block;
  text-align: left;
  padding: 12px;
  border-radius: 11px;
  border: solid 0.5px var(--gray020);
  background-color: var(--white);
}

.text {
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
}

.text img {
  max-width: 100%;
  height: auto;
}

div.avatar{
  width: 100%;
  height: 20px;
  position: relative;
}
div.avatar img {
  width:27px;
  height:27px;
  margin-right: 8px;
}
div.avatar .avatarnm {
  display: inline-block;
  max-width: calc(100% - 27px - 8px);
  vertical-align: top;
  color: var(--gray040);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
div.box {
  margin-left: 35px;
}
span.text p {
  margin: 0;
}
span.text ul, span.text ol {
  margin: 0;
  padding-inline-start: 14px;
}
span.text blockquote {
  margin: 0;
}