div.pick_chatbox_area_normal {
  height: 296px;
}

div.pick_chatbox_area_large {
  height: 320px;
}

div.pick_chatbox {
  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: 16px;
  border: solid 1px var(--gray010);
  background-color: rgba(242, 242, 247, 0.5);

  cursor: pointer;
}
