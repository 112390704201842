div.pick_title {
  padding: 20px 0 0;
}

div.pick_title h1 {
  display: inline-block;
  height: 21px;
}

div.service_info {
  color: var(--gray030);
  height: 1rem;
  max-height: 1rem;
  margin: 0;
  margin-top: 8px;
}

div.service_info img {
  display: block;
  margin: 0;
  margin-right: 4px;
  float: left;

  cursor: pointer;
}

div.service_info span {
  display: block;
  margin: 0;
  float: left;

  cursor: pointer;
}

div.service_info .sp {
  margin-right: 0.25rem;
}

div.service_info .ellipse {
  max-width: calc(50% - 64px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
}

div.pick_writer {
  padding: 16px 0 12px;
  height: 36px;
}

div.pick_writer img.avatar_img {
  width: 36px;
  height: 36px;
  object-fit: contain;
  border-radius: 12px;
  margin-right: 8px;
}
div.pick_writer div.avatar_text {
  display: inline-block;
  line-height: 36px;
  height: 36px;
  vertical-align: top;
}
div.pick_writer div.avatar_text span.avatarid {
  display: block;
  text-align: left;
  color: var(--black);
}
div.pick_writer div.avatar_text span.date {
  display: block;
  text-align: left;
  color: var(--gray030);
}
