div.pick_like_wrap {
  margin: 0;
  width: 100%;
}

div.pick_like {
  margin: 0;
  margin-top: 8px;

  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;  
}

div.pick_like img {
  display: block;
  margin: 0;
  margin-right: 4px;

  cursor: pointer;
}

div.pick_like span {
  display: block;
  margin: 0;
  line-height: 1;
  color: var(--gray030);

  cursor: pointer;
}

div.pick_like img.comment_icon {
  margin-left: 10px; 
}

div.pick_like img.share_icon {
  margin-left: auto; 
}
