body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, div, footer, span, hr {
  margin: 0;
  padding: 0;
}

#root{
  max-width: 720px;
  min-width: 360px;
  margin: 0 auto;
}

.sheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  visibility: visible;
  transition: opacity 0.5s, visibility 0.5s; 
}

.sheet[aria-hidden="true"] {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.sheet .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: var(--gray030);
  opacity: 0.5;
}

.sheet .contents {
  display: flex;
  flex-direction: column;

  border-radius: 1rem 1rem 0 0;

  background: var(--white);

  overflow-y: hidden;

  --default-transitions: transform 0.5s, border-radius 0.5s;

  transition: var(--default-transitions);
  transform: translateY(0);

  width: 100%;
  min-width: 360px;
  max-width: 720px;
  margin: 0 auto;

  box-sizing: border-box;
}

.sheet .contents:not(.not-selectable) {
  transition: var(--default-transitions), height 0.5s;
}

.sheet .contents.fullscreen {
  border-radius: 0;
}

.sheet[aria-hidden="true"] .contents {
  transform: translateY(100%);
}

.sheet .controls {
  display: flex;
}

.sheet .body {
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-direction: column;

  gap: 1rem;

  padding: 0;
  box-sizing: border-box;
}
