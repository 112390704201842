img.log_symbol_20 {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

header.app_bar {
  width: 100%;
  height: 54px;

  margin: 0;
  padding: 0;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  background-color: var(--white);
}

header.app_bar[aria-hidden="true"] {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

div.app_bar_shadow {
  display:block;
  height: 54px;
  width: 100%;
  min-width: 360px;
  min-height: 54px;
}

header.app_bar .app_bar_pos {
  min-width: 360px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  height: 54px;
  padding: 0;
}

header.app_bar img {
  cursor: pointer;
}

header.app_bar .body {
  margin: 11px 16px;
  padding: 0;
  height: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;
}
header.app_bar .body::after{
  position: absolute;
  content:'';
  left:0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--gray010);
}

header.app_bar button {
  width: 6.5em;
  height: 32px;
  margin-left: auto;
  border: none;
  border-radius: 6px;
  background-color: var(--purple020);
  color: var(--purple050);

  cursor: pointer;
}
