.pick_wrap {
  margin: 32px 20px 32px;
}
.pick_title {
  margin-bottom: 0px;
}
.pick_title > h1 {
  color: #000;
}

div.service_info {
  color: var(--gray030);
  height: 1rem;
  max-height: 1rem;
  margin: 0;
  margin-top: 6px;

  user-select: none;
}
div.service_info img {
  display: block;
  margin: 0;
  margin-right: 4px;
  float: left;

  cursor: pointer;
}
div.service_info span {
  display: block;
  margin: 0;
  float: left;

  cursor: pointer;
}
div.service_info .sp {
  margin-right: 0.25rem;
}
div.service_info .ellipse {
  max-width: calc(50% - 64px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
}

div.pick_writer {
  padding: 16px 0 20px;
  height: 36px;
}

div.pick_writer img.avatar_img {
  width: 36px;
  height: 36px;
  object-fit: contain;
  border-radius: 12px;
  margin-right: 8px;
}

div.pick_writer div.avatar_text {
  display: inline-block;
  line-height: 36px;
  height: 36px;
  vertical-align: top;
}

div.pick_writer div.avatar_text span.avatarid {
  display: block;
  text-align: left;
  color: var(--black);
}

div.pick_writer div.avatar_text span.date {
  display: block;
  text-align: left;
  color: var(--gray030);
}

div.pick_chatbox_area {
  height: 100%;
}

div.pick_chatbox {
  width: 100%;

  border-radius: 16px;
  border: solid 1px var(--gray010);
  background-color: rgba(242, 242, 247, 0.5);
}

hr.more_sp {
  border: 0;
  margin: 0;
  border-top: 8px solid var(--gray010);
}

div.more_wrap {
  max-width: 680px;
  min-width: 320px;

  padding: 32px 20px;
}

div.more_wrap h2 {
  margin-bottom: 24px;
  color: var(--black);
}

div.more_wrap h2 span {
  color: var(--purple050);
}

div.more_wrap div.more {
  width: calc(100% + 4px);
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  position: relative;
  left: -2px;
}

div.more div.thumb {
  height: 5rem;
  flex: 0 0 calc(33.33% - 24px);
  margin: 2px;
  padding: 16px 10px;
  border-radius: 12px;
  background-color: rgba(175, 82, 222, 0.05);

  --user-select: none;
  cursor: pointer;
}

div.thumb h3.thumb_t {
  color: var(--black);
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

div.thumb p.thumb_b {
  margin: 0;
  margin-top: 6px;
  color: var(--gray040);
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

div.page_more {
  width: 100%;
  height: 1px;
}

hr.pick_footer_sp {
  border: 0;
  margin: 0;
  border-top: 1px solid var(--gray010);
}

footer.pick_footer {
  max-width: 720px;
  min-width: 360px;
  width: 100%;
  height: 65px;
  position: fixed;
  padding: 0;
  bottom: 0;
  background-color: var(--white);
}

div.pick_footer_shadow {
  max-width: 720px;
  min-width: 360px;
  width: 100%;
  height: 65px;
}

div.pick_like_wrap {
  margin: 0;
  width: 100%;
}

div.pick_like {
  margin: 16px 20px;

  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;  
}

div.pick_like img {
  display: block;
  margin: 0;
  margin-right: 4px;

  cursor: pointer;
}

div.pick_like span {
  display: block;
  margin: 0;
  line-height: 1;
  color: var(--gray030);

  cursor: pointer;
}

div.pick_like img.comment_icon {
  margin-left: 12px; 
}

div.pick_like img.share_icon {
  margin-left: auto; 
}
