div.main {
  margin: 24px 20px 0;
}

div.main > div {
  width: 100%;
}

div.main > img {
  display: block;
  margin: 0 auto;
}

div.main > p.title {
  width: 100%;
  margin: 8px 0 7px;
  padding: 8px 0 25px;

  text-align: center;
  color: var(--black);
}

div.main > button.btn_app {
  width: 100%;
  height: 47px;
  border: 0;
  border-radius: 8px;
  background-color: var(--purple050);
  color: var(--white);

  cursor: pointer;
}

div.main > button.btn_web {
  width: 100%;
  height: 44px;
  border: 0;
  border-radius: 8px;
  background-color: var(--white);
  color: var(--gray030);

  cursor: pointer;
}
