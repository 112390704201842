.web_place_main {
  width: 100%;
  background-color: var(--white);
}

.web_place_main>div.body {
  margin: 0 20px;
}

img.log_symbol_20 {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

div.bottom_fb_shadow {
  display:block;
  height: 87px;
  width: 100%;
  min-width: 360px;
  min-height: 72px;
}

div.bottom_fb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  visibility: visible;
}

div.bottom_fb[aria-hidden="true"] {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

div.bottom_fb > .contents {
  display: flex;
  flex-direction: column;

  height: 72px;
  width: 100%;
  min-width: 360px;
  max-width: 720px;

  border-radius: 12px 12px 0 0;
  box-shadow: 0 -12px 20px 0 rgba(196, 196, 214, 0.4);
  background: var(--white);
}

div.bottom_fb .main {
  padding: 20px 16px;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
}

div.bottom_fb .main img {
  width: 32px;
  height: 32px;

  cursor: pointer;
}

div.bottom_fb .main .app_title {
  height: 32px;
  width: 10em;
  position: relative;
}

div.bottom_fb .main h3 {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  cursor: pointer;
}

div.bottom_fb .logo {
  height: 20px;

  display: flex;
  justify-content: start;
  
  gap: 8px;

}

div.bottom_fb button {
  margin-left: auto;

  width: 3.5em;
  height: 32px;
  padding: 0;

  border: 0;
  border-radius: 6px;
  background-color: var(--purple050);
  color: var(--white);

  cursor: pointer;
}

div.qua_place_cont {
  min-width: 320px;
  display: block;
  padding: 0;
  position: relative;
}

hr.qua_pick_sp {
  border: 0px;
  margin-top: 19.5px;
  border-top: 1px solid var(--gray010);
}
