.container {
  max-width: 67%;
  margin: 24px 16px 24px auto;
  text-align: right;
}

.box {
  display: inline-block;
  text-align: left;
  padding: 12px;
  border-radius: 11px;
  background-color: var(--purple050);
}

.text {
  text-align: right;
  color: var(--white);
  word-break: break-all;
  word-wrap: break-word;
}

.text img {
  max-width: 100%;
  height: auto;
}
