div.ground {
  width: 100%;
  background-color: var(--white);
}

div.ground div.body {
  padding: 0;
  margin: 0;
  min-width: 360px;
  max-width: 720px;
  height: 100vh;

  display: flex;
  align-items: center;
}

div.inform {
  width: 280px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-self: start;
  align-items: center;
}

div.inform h2 {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

div.inform p {
  width: 100%;
  margin: 20px 0 32px;
  padding: 0;
  text-align: center;
  color: var(--gray040);
}

div.inform button {
  width: 100%;
  height: 47px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 8px;
  color: var(--white);
  background-color: var(--purple050);
}